<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="보호구 지급 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn
                v-if="editable && !disabled && param.requestGiveInfoId"
                :url="completeUrl"
                :isSubmit="isComplete"
                :param="data"
                :mappingType="completeType"
                label="지급완료"
                icon="check"
                @beforeAction="completeData"
                @btnCallback="completeCallback" 
              />
              <c-btn
                v-if="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="saveType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-datepicker
                :required="true"
                label='지급일'
                :disabled="disabled"
                :editable="editable"
                name="giveDate"
                v-model="data.giveDate"
              />
            </div>
            <div class="col-3">
              <c-dept
                :required="true"
                label='지급부서'
                :disabled="disabled"
                :editable="editable"
                name="giveDeptCd"
                v-model="data.giveDeptCd"
              />
            </div>
            <div class="col-3">
              <c-field
                :required="true"
                label='불출담당자'
                :disabled="disabled"
                :editable="editable"
                name="giveUserId"
                v-model="data.giveUserId"
              />
            </div>
            <div class="col-3">
              <c-plant
                v-if="param.isRequest==='N'"
                type="edit"
                :required="true"
                label='사업장'
                :disabled="disabled"
                :editable="editable"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="보호구 지급 목록"
        tableId="table"
        :columns="grid.columns"
        :data="data.detailList2"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable&&!disabled"
        gridHeight="600px"
        selection="multiple"
        rowKey="giveListId"
      >
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable&&!disabled&&param.isRequest==='Y'" label="신청한 보호구 선택" icon="save_alt" @btnClicked="addRequestGear" />
              <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addGear" />
              <c-btn v-if="editable&&!disabled" label="제외" icon="remove" @btnClicked="removeGear" />
            </q-btn-group>
          </div>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'userArray'">
            <c-multi-field 
              :editable="editable"
              :disabled="disabled"
              :userInfo.sync="userInfo"
              isArray
              type="user"
              label="" 
              :name="`userArray${props.rowIndex}`" 
              v-model="props.row[col.name]"
              @dataChange="dataChange(props, col)" />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-gear-give-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        requestGiveInfoId: '',
        stepCd: '',
        plantCd: '',
        giveTypeCd: '',
        isRequest: 'Y'
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        requestDate: '',
        requestDeptCd: '',
        requestUserId: '',
        giveDt: '',
        giveTypeCd: '',
        giveDeptCd: '',
        giveUserId: '',
        sysApprovalRequestId: '',

        detailList: [],
        detailList2: [],
        deleteDetailList2: [],
      },
      grid: {
        columns: [
          {
            name: 'safetyTypeName',
            field: 'safetyTypeName',
            label: '보호구종류',
            style: 'width:120px',
            align: 'center',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구명',
            style: 'width:200px',
            align: 'left',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:80px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            required: true,
            name: 'giveCount',
            field: 'giveCount',
            label: '지급수량',
            style: 'width:80px',
            align: 'right',
            type: 'number',
            setHeader: true,
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            headType: 'multiUser',
            name: 'userArray',
            field: 'userArray',
            label: '수령인',
            style: 'width:300px',
            align: 'left',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '지급보호구 비고',
            style: 'width:250px',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      isApproval: false,
      disable: false,
      saveUrl: 'transactionConfig.sop.sas.give.insert.url',
      saveType: 'POST',
      isSave: false,
      completeUrl: 'transactionConfig.sop.sas.give.update.url',
      completeType: 'PUT',
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      disabled: false,
    };
  },
  computed: {
    userInfo() {
      return {
        userItems: [],
        userText: 'userName',
        userValue: 'userId',
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.sas.give.get.url;
      this.insertUrl = transactionConfig.sop.sas.give.insert.url;
      this.updateUrl = transactionConfig.sop.sas.give.update.url;

      this.getDetail();
    },
    getDetail() {
      if (this.param.requestGiveInfoId) {
        this.$http.url = this.$format(this.getUrl, this.param.requestGiveInfoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data

          if (this.data.giveUserId == '') {
            this.data.giveUserId = this.$store.getters.user.userId;
          }

          if (_result.data.statusCd === 'SSC0000015') {
            this.disabled = true;
          }
          if (!_result.data.detailList2 || _result.data.detailList2.length === 0) {
            this.$_.forEach(_result.data.detailList, item => {
              this.data.detailList2.push({
                giveListId: uid(),
                requestGiveInfoId: this.param.requestGiveInfoId,
                safetyGearId: item.safetyGearId,
                safetyTypeName: item.safetyTypeName,
                safetyGearName: item.safetyGearName,
                requestCount: item.requestCount,
                giveCount: item.requestCount,
                remarks: '',
                regUserId: this.$store.getters.user.userId,
                editFlag: 'C',
                userArray: [],
              })
            })
          } else {
            this.$_.forEach(_result.data.detailList2, item => {
              if (!item.userArray) item.userArray = [];
              this.$_.forEach(item.userList, _item => {
                item.userArray.push(_item.giveUserId)
              })
            })
          }
        },);
      } 
    },
    saveData() {
      if (this.data.requestGiveInfoId) {
        this.data.chgUserId = this.$store.getters.user.userId
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.data.regUserId = this.$store.getters.user.userId
        this.saveUrl = this.insertUrl;
        this.$set(this.data, 'giveTypeCd', this.param.giveTypeCd)
        this.saveType = 'POST';
      }

      if (this.data.detailList2.length == 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '지급할 보호구를 하나 이상 등록하세요.', // 신청할 보호구를 하나 이상 등록하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.data.detailList2)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$set(this.data, 'statusCd', 'SSC0000010')
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.requestGiveInfoId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeData() {
      if (this.data.detailList2.length == 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '지급할 보호구를 하나 이상 등록하세요.', // 신청할 보호구를 하나 이상 등록하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.data.detailList2)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '지급완료 하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.chgUserId = this.$store.getters.user.userId
                this.$set(this.data, 'statusCd', 'SSC0000015')
                this.completeUrl = this.updateUrl;
                this.completeType = 'PUT';
                this.isComplete = !this.isComplete;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.param.requestGiveInfoId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.disabled = true;
      this.getDetail();
    },
    addRequestGear() {
      this.popupOptions.title = '보호구 신청 상세'; // 보호구
      this.popupOptions.param = {
        type: 'multiple',
        requestGiveInfoId: this.param.requestGiveInfoId,
      };
      this.popupOptions.target = () => import(`${'./safetyRequestGearPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeGearRequestPopup;
    },
    closeGearRequestPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.detailList2, { safetyGearId: item.safetyGearId }) === -1) {
            this.data.detailList2.push({
              giveListId: uid(),
              requestGiveInfoId: this.param.requestGiveInfoId,
              safetyGearId: item.safetyGearId,
              safetyTypeName: item.safetyTypeName,
              safetyGearName: item.safetyGearName,
              giveCount: item.requestCount,
              userArray: [],
              remarks: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    addGear() {
      this.popupOptions.title = '보호구 검색'; // 보호구 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'./safetyGearPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeGearPopup;
    },
    closeGearPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.detailList2, { safetyGearId: item.safetyGearId }) === -1) {
            this.data.detailList2.push({
              giveListId: uid(),
              requestGiveInfoId: this.param.requestGiveInfoId,
              safetyGearId: item.safetyGearId,
              safetyTypeName: item.safetyTypeName,
              safetyGearName: item.safetyGearName,
              giveCount: '',
              userArray: [],
              remarks: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeGear() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteDetailList2) {
          this.data.deleteDetailList2 = []
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.data.deleteDetailList2, { giveListId: item.giveListId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteDetailList2.push(item)
          }
          this.data.detailList2 = this.$_.reject(this.data.detailList2, item)
        });
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    dataChange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  },
};
</script>
